import React, { Component } from "react"
import Layout from "../components/Layout/layout"

class ContactPage extends Component {
  render() {
    let contactData = this.props.data.allContactJson.nodes[0]
    return (
      <Layout page={"contact"}>
        <h1 className="title">{contactData.title}</h1>
        <div className="text">
          {contactData.text}
        </div>
        <div className="contact-grid-container">
          <a href="mailto:Katlin.NBAnderson@gmail.com" className="contact-grid-item">
            <img
              src={contactData.email_icon}
              alt="Email"
              className="contact-icon"
            ></img>
          </a>
          <a href="https://github.com/KNBAnderson" className="contact-grid-item">
            <img
              src={contactData.github_icon}
              alt="github"
              className="contact-icon"
            ></img>
          </a>
          <a href="https://www.linkedin.com/in/katlinanderson/" className="contact-grid-item">
            <img
              src={contactData.linkedin_icon}
              alt="linkedin"
              className="contact-icon"
            ></img>
          </a>
        </div>
      </Layout>
    )
  }
}
export default ContactPage

export const query = graphql`
  query {
    allContactJson {
      nodes {
        id
        title
        description
        text
        email_icon
        github_icon
        linkedin_icon
      }
    }
  }
`
